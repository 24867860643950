import React from 'react';
import { SlLocationPin } from 'react-icons/sl'
import { BiPhoneCall } from 'react-icons/bi'
import { TbWorld } from 'react-icons/tb'
import { BsWhatsapp } from 'react-icons/bs'
import { MdOutlineEmail } from 'react-icons/md'

const ContactInfo = () => {
    return (
        <div>
            <div className='bg-slate-400 pb-5' data-aos="fade-up" data-aos-duration="3000">
                <p className='text-center text-lg'>Home/Contact</p>
                <p className='text-center text-4xl'>Contact US</p>

            </div>
            <div className='grid grid-cols-4 gap-10 mx-20 mt-10'>
                <div className='rounded  border-2'>
                    <div className='text-[32px] mt-3 border-2 border-dotted rounded-[50%] mx-32   p-[10px] justify-center flex '><SlLocationPin /></div>
                    <h1 className='text-xl text-center'>Our Adress</h1>
                    <p className='text-xl text-center'>Reliable Traders, 814 Raviwar peth</p>
                    <p className='text-xl text-center'>Satranjiwala Chouk, Pune</p>
                    <p className='text-xl text-center mb-3'>MAHARASHTRA INDIA - 411002</p>
                </div>
                <div className='rounded  border-2'>
                    <p className='text-[32px] mt-3 border-2 border-dotted rounded-[50%] mx-32   p-[10px] justify-center flex '><BiPhoneCall /></p>
                    <h1 className='text-xl text-center'>Call Us</h1>
                    <p className='text-xl text-center'>Murtaza Bhai Patwa <br /> +91 90284 78652</p>
                    <p className='text-xl text-center'>Mohammad bhai Patwa <br />+91 97620 10786</p>
                    <p className='text-xl text-center mb-3'>+91 93710 04167</p>
                </div>
                <div className='rounded  border-2'>
                    <p className='text-[32px] mt-3 border-2 border-dotted rounded-[50%] mx-32   p-[10px] justify-center flex '><TbWorld /></p>
                    <p className='text-xl text-center'>Also find us on social Media Platform</p>
                    <div className='grid grid-cols-4 mx-16 gap-4'>
                        <p className='text-[32px] mt-3 justify-center flex'>
                            <a href='https://wa.me/+919028478652' target='_blank' rel="noreferrer"><BsWhatsapp /></a>
                        </p>
                        <p className='text-[16px] mt-3 justify-center flex'>
                            <a href='https://www.indiamart.com/reliable-traders' target='_blank' rel="noreferrer">India Mart</a>
                        </p>
                    </div>
                </div>
                <div className='rounded  border-2'>
                    <p className='text-[32px] mt-3 border-2 border-dotted rounded-[50%] mx-32   p-[10px] justify-center flex '><MdOutlineEmail /></p>
                    <h1 className='text-xl text-center'>Email Us</h1>
                    <p className='text-xl text-center mb-3'>reliabletraders@live.in</p>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;