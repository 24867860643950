// import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useState, useEffect } from 'react';
import '../assets/css/productCss.css'

function Product() {
    const [popup, setPopup] = useState(false)
    // const closePopup = () => {
    //     setPopup(false)
    // }
    const handleClickOpen = () => {
        setPopup(!popup)
    }
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        fetch('https://reliabletraders.co.in/api/product-categories/')
            .then(response => response.json())
            .then(data => setCategories(data));
    }, []);

    if (!categories) {
        return <div>Loading Products...</div>;
    }
    return (
        <div>
            <div className='bg-slate-400 pb-5' data-aos="fade-up" data-aos-duration="3000">
                <p className='text-center text-lg'>Home/Product</p>
                <p className='text-center text-4xl font-bold'>Our Product</p>
            </div>
            {Object.keys(categories).map(categoryName => {
                return (
                    <div key={categoryName}>
                        <div>
                            <p className='text-center text-4xl font-bold'>{categoryName} ({categories[categoryName].count})</p>
                        </div>
                        <div>
                            <div className='grid' >
                                {categories[categoryName].products.map(product => (
                                    <div key={product.id}>
                                        <div className="card  bg-base-100 shadow-xl" data-aos="fade-right">
                                            <img className='h-[50%]' src={`https://reliabletraders.co.in/media/${product.img}`} alt="Product" onClick={handleClickOpen} />
                                            <div className="card-body">
                                                <h2 className="card-title">
                                                    {product.name}
                                                </h2>
                                                {/* <p >{product.description} </p> */}
                                                {/* <p className='-mt-7'>{product.price}$</p> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default Product;