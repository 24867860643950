import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import hex_bolts from '../assets/images/hex_bolts.jpeg'
import hex_nut from '../assets/images/hex_nut.jpeg'
import ms_threaded_rods from '../assets/images/ms_threaded_rods.jpeg'
import ss_threaded_rod from '../assets/images/ss_threaded_rod.jpeg'
import ss_allen_cap from '../assets/images/ss_allen_cap.jpeg'
import washers from '../assets/images/washers.jpeg'




const Collection = () => {
    return (
        <div className='mx-[8%] mt-20' data-aos="fade-up" data-aos-duration="3000">
            <p className='text-center text-5xl font-bold mb-10' >Our Collection</p>
            <Swiper
                slidesPerView={window.innerWidth< 768? 1 : 4}
                spaceBetween={30}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='border-2 hover:border-b-0 border-blue-900 mb-8'>
                        <img src={hex_bolts} alt='Collection' />
                        <p className='text-black text-center text-base '>Hex Bolts</p>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='border-2 hover:border-b-0 border-blue-900 mb-8'>
                        <img src={hex_nut} alt='Collection' />
                        <p className='text-black text-center text-base'>Hex Nut</p>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='border-2 hover:border-b-0 border-blue-900 mb-8' href="#" >
                        <img src={washers} alt='Collection' />
                        <p className='text-black text-center text-base'>Washers</p>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='border-2 hover:border-b-0 border-blue-900 mb-8' href="#" >
                        <img src={ss_allen_cap} alt='Collection' />
                        <p className='text-black text-center text-base '>SS Allen Cap</p>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='border-2 hover:border-b-0 border-blue-900 mb-8' href="#" >
                        <img src={ms_threaded_rods} alt='Collection' />
                        <p className='text-black text-center text-base'>MS Threaded Rods</p>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='border-2 hover:border-b-0 border-blue-900 mb-8'>
                        <img src={ss_threaded_rod} alt='Collection' />
                        <p className='text-black text-center text-base '>SS Threaded Rods</p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Collection;