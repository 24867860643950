import React from 'react';
import Map from '../Home/Map';
import "../assets/css/mapCss.css"
import { BsWhatsapp} from 'react-icons/bs'


const Footer = () => {
    return (

        <div className='mt-20'>
            <div className='bg-[#000080] text-white flex flex-col lg:flex-row justify-center gap-x-10 pt-10 p-10 pb-10'>
                <div>
                <h1 className='text-4xl font-bold'>Reliable Traders</h1>
                <p className='text-xl mt-5'>GST 27AABFR5072Q1ZN</p>
                <p className='text-xl mt-5'>814 RAVIWAR PETH</p>
                <p className='text-xl mt-2'>SATRANJIWALA CHOWK</p>
                <p className='text-xl mt-2'>PUNE MAHARASHTRA</p>
                <p className='text-xl mt-2'>INDIA 411002</p>


                <p className='text-xl mt-5'>Murtaza bhai Patwa:</p>
                <p className='text-xl mt-2'>+91 90284 78652</p>
                <p className='text-xl mt-5'>Mohammad bhai Patwa:</p>
                <p className='text-xl mt-2'>+91 97620 10786</p>
                <p className='text-xl mt-2'>+91 93710 04167</p>
                <p className='text-xl mt-5'>Office:</p>
                <p className='text-xl mt-2'>0202256759</p>
                </div>
                <div className='mt-10 flex justify-center map-container'>
                    <Map />
                </div>
                <div className=''>
                <p className='text-4xl text-center mb-3'>Our Social Network</p>
                <div className='flex justify-center gap-x-5 mt-5 ml-20'>
                    <a href='https://wa.me/+919028478652' target="_blank" rel="noreferrer">
                    <button className='text-2xl'>
                        <BsWhatsapp />
                    </button>
                    </a>

                    {/* <a href='https://www.indiamart.com/reliable-traders' target="_blank" rel="noreferrer">
                    <button className='text-2xl'>
                    India Mart
                    </button>
                    </a> */}
                </div>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row justify-between px-10 bg-[#007aff] py-5 text-white'>
                <p>© Copyright Reliable Traders. All Rights Reserved</p>
                <p>Designed & Developed by Burhani Technologies</p>
            </div>
        </div>
    );
};

export default Footer;