import React from 'react';
import img1 from '../assets/images/about01.jpg'
import img2 from '../assets/images/about02.jpg'
import img3 from '../assets/images/about03.jpg'
import img4 from '../assets/images/about04.jpg'
import img5 from '../assets/images/about05.jpg'

const AboutUs = () => {
    return (
        <div >
            <div className='bg-slate-400 pb-5' data-aos="fade-up" data-aos-duration="3000">
                <p className='text-center text-lg'>Home/About</p>
                <p className='text-center text-4xl'>Find Out More About US</p>

            </div>
            <div className=' md:grid md:grid-cols-2 md:mt-5 md:gap-20 md:mx-20'>
                <div>
                    <img data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine" src={img1} alt='About us' />
                </div>
                <div data-aos="fade-up" data-aos-duration="3000" className="mt-5 md:mt-0">
                    <h1 className='text-3xl font-semibold'>Reliable Traders</h1>
                    <p className='mt-3'>
                        Established in the year <b>1975</b>, we, <b>“Reliable Traders”</b>, are a partnership firm, engaged in trading and supplying a wide range of <b>Machine Screws, Nuts & Bolts, Fasteners and Threaded Bars.</b> The entire range is procured from reputed vendors of the market, who make use of high-grade stainless steel and other raw material in the production. Our products are renowned in the excellent their excellent finish, dimensional accuracy and corrosion resistance features.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        We have set up a spacious and capacious warehouse that is based at <b>Pune, Maharashtra, India.</b> This facility enables us to store the procured products in a safe and systematic manner. Our team of procuring agents makes sure that the vendors associated with us are reliable and offer a qualitative range of products. Moreover, we have a separate unit of quality controlling, which is run by a team expert professional. They test the products on well-defined parameters to deliver defect free range. All the skilled and experienced professionals appointed by us, works dedicatedly to run our business smoothly and systematically, enabling us serve the customers in the best way possible.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Under the guidance of our mentor <b>'Mr. Mohammad Patwa',</b> we have achieved a remarkable position in this competitive market over these years. With the help of his constant motivation, dedication, strong business acumen and an enriching industry experience, we have gained a huge customer base across the nation.
                    </p>
                </div>

                <div>
                    <img
                        data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        src={img2}
                        alt="About Us"
                        className="w-full"
                    />
                </div>
                <div data-aos="fade-up" data-aos-duration="3000" className="mt-5 md:mt-0">
                    <h1 className='text-3xl font-semibold'>Why Us?</h1>
                    <p className='mt-3'>
                        With an extensive industrial experience, we have established ourselves as one of the leading firms engaged in offering quality products to the valued clients. Owing to the timely delivery of our quality approved products, transparency in dealings and ethical business policies, we have achieved steady growth in the industry. The attributes, for which we are well known among the customers as well as in this competitive market are:
                    </p>
                    <p>&nbsp;</p>
                    <ol style={{ listStyleType: 'decimal' }}>
                        <li>
                            <b>Capacious 	warehouse</b>
                        </li>
                        <li>
                            <b>Quality range of products</b>
                        </li>
                        <li>
                            <b>Rich vendor base</b>
                        </li>
                        <li>
                            <b>Timely delivery of consignments</b>
                        </li>
                        <li>
                            <b>Wide distribution network</b>
                        </li>
                        <li>
                            <b>Client-centric approach</b>
                        </li>
                    </ol>
                </div>

                <div>
                    <img
                        data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        src={img3}
                        alt="About Us"
                        className="w-full"
                    />
                </div>

                <div data-aos="fade-up" data-aos-duration="3000" className="mt-5 md:mt-0">
                    <h1 className='text-3xl font-semibold'>Warehousing and Packaging Logistics</h1>
                    <p className='mt-3'>
                    We have established a commodious and comprehensive warehouse, which is located at the industrial heart of Pune, (Maharashtra-India). This unit is looked after by a team of expert professionals, which is responsible to keep all the procured range in a systematic and organized manner. Segmented into various section, the unit helps us keep the products according to their category even in bulk quantity. Moreover, this unit has a proper temperature and moisture controlling facilities to prevent the offered range from corrosion. Moreover, we have appointed a separate team for packing all the offered range in a safe manner. They also ensure that the industrial products offered by us are packed in quality packaging material so that they remain safe during:
                    </p>
                    <p>&nbsp;</p>
                    <ul style={{listStyleType: 'disc'}} className='ml-10'>
                        <li>
                            Transportation
                        </li>
                        <li>
                            Loading
                        </li>
                        <li>
                            Unloading
                        </li>
                    </ul>
                </div>

                <div>
                    <img
                        data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        src={img4}
                        alt="About Us"
                        className="w-full"
                    />
                </div>

                <div data-aos="fade-up" data-aos-duration="3000" className="mt-5 md:mt-0">
                    <h1 className='text-3xl font-semibold'>Our Team</h1>
                    <p className='mt-3'>
                    Our organization is supported by a team of highly competent and proficient professionals, which assists us to provide a wide range of products to our valued clients. In order to deliver a quality adhering range of industrial products to the valued clients, we have appointed these professionals after close inspection of their skills, knowledge and experience. Our professionals are hard working and are highly skilled that helps them in offering defect free products on time. Some key members of our workforce are:
                    </p>
                    <p>&nbsp;</p>
                    <ul style={{listStyleType: 'disc'}} className='ml-10'>
                        <li>
                        Procurement agents
                        </li>
                        <li>
                        Quality analysts
                        </li>
                        <li>
                        Warehouse personnel
                        </li>
                        <li>
                        Packaging experts
                        </li>
                        <li>
                        Sales & administrative staff
                        </li>
                    </ul>

                    <p className='mt-3'>
                    All these professionals are provided with various training on a regular interval in order to keep them updated with the latest market dynamics. Furthermore, our professionals keep a close coordination with our patrons, in order to understand their exact requirements.
                    </p>
                </div>

                <div>
                    <img
                        data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        src={img5}
                        alt="About Us"
                        className="full"
                    />
                </div>

                <div data-aos="fade-up" data-aos-duration="3000" className="mt-5 md:mt-0">
                    <h1 className='text-3xl font-semibold'>Vendor Base</h1>
                    <p className='mt-3'>
                    Our organization is associated with some of the well known manufacturers of the market that enable us to offer premium quality products to our valued patrons. In order to conduct various research about vendor's background, we have appointed a dedicated team of procuring agents, which selects these vendors on the basis of the following parameters:
                    </p>
                    <p>&nbsp;</p>
                    <ul style={{listStyleType: 'disc'}} className='ml-10'>
                        <li>
                        Streamlined manufacturing process
                        </li>
                        <li>
                        Technology used
                        </li>
                        <li>
                        Industry experience
                        </li>
                        <li>
                        Market goodwill
                        </li>
                        <li>
                        Timely delivery assurance
                        </li>
                        <li>
                        Quality products
                        </li>
                        <li>
                        Financial stability
                        </li>
                        <li>
                        Capability of undertaking bulk consignments
                        </li>
                    </ul>

                    <p className='mt-3'>
                    We maintain a healthy relationship with our reliable vendors that help us achieve the organizational goals by serving our customers in the best possible manner.
                    </p>
                </div>




            </div>
        </div>
    );
};

export default AboutUs;