import React from 'react';
import home01 from '../assets/images/home01.png'
import home02 from '../assets/images/home02.jpg'
import home03 from '../assets/images/home03.jpg'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation} from "swiper";

const Carousel = () => {
    return (
        <div >

<Swiper
        slidesPerView={1}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
        modules={[Navigation,Autoplay]}
        className="mySwiper"
      >
           <SwiperSlide><img className='w-[100%]' src={home01} alt='homepageimg'/></SwiperSlide> 
           <SwiperSlide><img className='w-[100%]' src={home02} alt='homepageimg'/></SwiperSlide> 
           <SwiperSlide><img className='w-[100%]' src={home01} alt='homepageimg'/></SwiperSlide> 
           <SwiperSlide><img className='w-[100%]' src={home03} alt='homepageimg'/></SwiperSlide> 
       
        </Swiper>

</div>
    );
};

export default Carousel;