import React from "react";
import { Link } from "react-router-dom";
import logo from '../assets/images/logo.png'

const Navbar = () => {
  const handleAdminLogin = () => {
    window.location.href = 'https://reliabletraders.co.in/admin/';
  };
  return (
    <div className="navbar bg-base-100">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg
              xmlns="https://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About US</Link>
            </li>
            <li>
              <Link to="/product">Product</Link>
            </li>
            <li>
              <Link to="/contact">Contact US</Link>
            </li>
            <li onClick={handleAdminLogin}>
              <Link to="/" >Admin Login</Link>
            </li>
          </ul>
        </div>
        <img className="hidden sm:block h-[75px] ml-10 sm:h-[45px] sm:ml-5" src={logo} alt="Navbar"/>
        <h1 className=' ml-10 text-4xl font-bold text-xl sm:text-4xl sm:ml-5'>Reliable Trader</h1>
      </div>
      <div className="navbar-end hidden lg:flex">
        <ul className="menu menu-horizontal px-4 gap-x-5">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li >
            <Link to="/about">About US</Link>
          </li>
          <li>
            <Link to="/product">Product</Link>
          </li>
          <li>
            <Link to="/contact">Contact US</Link>
          </li>
          <li onClick={handleAdminLogin}>
            <Link to="/">Admin Login</Link>
          </li>

        </ul>
      </div>
    </div>
  );
};

export default Navbar;
