import React from 'react';

const Map = () => {
    return (
        <div class="col-lg-6">
            <iframe title='Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.2849778223795!2d73.85821321436875!3d18.516019774147207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c068e305709d%3A0x6725d67f3a6fe4ec!2sRELIABLE%20TRADERS!5e0!3m2!1sen!2sin!4v1679213913335!5m2!1sen!2sin" width={window.innerWidth <= 767 ? "100%" : "500"} height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
};

export default Map;