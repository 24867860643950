import React from 'react';
import about02 from '../assets/images/about02.jpg'

const Profile = () => {
    return (
        <div>
            <h1 className='text-5xl font-bold text-center mt-16' data-aos="fade-up" data-aos-duration="3000">Company Profile</h1>
            <p className='text-xl text-center mt-5 ' data-aos="fade-up" data-aos-duration="3000">Provide Customized Solutions</p>
            <div className='grid grid-cols-2 mt-5 gap-20 mx-20'>
                <div>
                    <img data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine" src={about02} alt='About us' />
                </div>
                <div className='text-center' data-aos="fade-up" data-aos-duration="3000">
                    <h1 className='text-3xl font-semibold'>Manufacture</h1>
                    <p className='mt-3'> Established in the year <b>1975</b>, we, <b>“Reliable Traders”</b>, are a partnership firm, engaged in trading and supplying a wide range of <b>Machine Screws, Nuts & Bolts, Fasteners and Threaded Bars.</b> The entire range is procured from reputed vendors of the market, who make use of high-grade stainless steel and other raw material in the production. Our products are renowned in the excellent their excellent finish, dimensional accuracy and corrosion resistance features.</p>
                </div>
            </div>
        </div>
    );
};

export default Profile;