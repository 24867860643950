import React from "react";
import { FaWhatsapp } from 'react-icons/fa';

const MiddleNavbar = () => {
  return (
    <div className="bg-[#040440] flex justify-between text-white px-[10%]">
      <div className="flex gap-x-2">
        <button className="text-sm sm:text-base mr-2">reliabletraders@live.in</button>
        <button className="text-sm sm:text-base mr-2">+91 97620 10786</button>
      </div>
      <div className="flex gap-x-3 ">
        {/* <button><a href="#"><BsInstagram /></a></button> */}
        <button><a href='https://wa.me/+919028478652' target='_blank' rel="noreferrer"><FaWhatsapp /></a></button>
        {/* <button><a href='https://www.indiamart.com/reliable-traders' target='_blank' rel="noreferrer">India Mart</a></button> */}
      </div>
    </div>
  );
};

export default MiddleNavbar;
